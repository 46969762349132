import React from "react";

function Nerve() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="97"
            height="87"
            fill="none"
            viewBox="0 0 97 87"
        >
            <g clipPath="url(#clip0_33_656)">
                <path
                    fill="#4C98B1"
                    d="M20.1 60.85a22.73 22.73 0 01-9.95-2.05 16.84 16.84 0 01-7.75-7.52A22.56 22.56 0 01.05 39.39c.26-3.86 1.45-7.33 3.17-9.3a1.53 1.53 0 012.67.906 1.51 1.51 0 01-.38 1.104c-3.66 4.18-2.67 13.46-.41 17.76 3.21 6.11 9.7 8.78 18.76 7.71a1.53 1.53 0 01.36 3 34.85 34.85 0 01-4.12.25v.03z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M5.98 34.36a1.51 1.51 0 01-1.07-.44 9.68 9.68 0 1113.62-13.75 1.524 1.524 0 11-2.15 2.16 6.55 6.55 0 00-4.66-1.92 6.64 6.64 0 00-4.64 11.34 1.53 1.53 0 01-1.07 2.61h-.03zM63.9 13.2a1.547 1.547 0 01-.37 0 1.53 1.53 0 01-1.11-1.85 6.631 6.631 0 00-12.86-3.24 1.522 1.522 0 01-2.95-.75 9.68 9.68 0 0118.77 4.73 1.53 1.53 0 01-1.48 1.15v-.04zM88.8 42.13a1.52 1.52 0 01-.64-2.9 6.64 6.64 0 003.645-7.576 6.62 6.62 0 00-9.215-4.444 1.53 1.53 0 11-1.29-2.77 9.678 9.678 0 0113.07 4.595 9.679 9.679 0 01-4.91 12.955c-.2.096-.419.144-.64.14h-.02z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M82.13 60.62c-.91-.003-1.817-.09-2.71-.26a14.349 14.349 0 01-9.25-6 1.53 1.53 0 011.543-2.361 1.52 1.52 0 01.977.641A11.39 11.39 0 1091.5 39.79a1.53 1.53 0 012.52-1.73 14.46 14.46 0 01-11.89 22.6v-.04zM85.3 25.99a1.53 1.53 0 01-1.51-1.32c-.4-3-2.19-8.75-9.59-13.45-4.69-3-9.13-3.88-10-3.61a1.534 1.534 0 11-1-2.9c2.33-.76 7.78.93 12.42 3.84 6.35 4 10.31 9.56 11.14 15.71a1.54 1.54 0 01-1.31 1.72l-.15.01zM38.77 69.58c-8.69 0-15.77-6.32-15.77-14.1a13.31 13.31 0 014.69-10 1.52 1.52 0 012 2.28 10.3 10.3 0 00-3.66 7.75c0 6.09 5.71 11 12.72 11a13.65 13.65 0 009.52-3.72 1.53 1.53 0 012.14 2.18 16.72 16.72 0 01-11.66 4.59l.02.02z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M59.6 66.79a29.11 29.11 0 01-10.52-1.81 1.53 1.53 0 011.19-2.81 29 29 0 0016.81.62 1.546 1.546 0 11.75 3 33.27 33.27 0 01-8.23 1zM75.38 70.5a1.52 1.52 0 01-.12-3c10.39-.85 12.61-10.87 12.63-11a1.534 1.534 0 013 .63 18.68 18.68 0 01-2.87 6.29 16.38 16.38 0 01-12.49 7.09l-.15-.01z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M81.11 86.75a1.51 1.51 0 01-1.49-1.24c-.93-4.94-6.59-9.46-14.43-11.51-8.65-2.27-9.59-8.28-9.63-8.53l3-.43c0 .18.8 4.3 7.39 6 9 2.35 15.5 7.8 16.64 13.9a1.53 1.53 0 01-1.22 1.78h-.28l.02.03zM86.48 85.7a1.54 1.54 0 01-1.5-1.56c.17-8.73-6.53-13.86-6.6-13.91a1.531 1.531 0 011.83-2.44c.33.24 8 6.13 7.82 16.41a1.53 1.53 0 01-1.52 1.5h-.03zM10.54 20.41a1.511 1.511 0 01-1.47-1.56 14.51 14.51 0 014.65-9.83c3.12-2.71 7.47-3.76 12.92-3.14a1.53 1.53 0 11-.35 3c-4.55-.52-8.1.28-10.55 2.39a11.64 11.64 0 00-3.62 7.64 1.53 1.53 0 01-1.53 1.47l-.05.03z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M24.52 11.62a1.44 1.44 0 01-.63-.14 1.53 1.53 0 01-.75-2 16.07 16.07 0 016.06-6.32C36.27-1.13 44.91.32 50.91 2.3a1.52 1.52 0 011 1.92 1.54 1.54 0 01-1.93 1c-7.73-2.55-14.36-2.35-19.17.57a13.33 13.33 0 00-4.87 5 1.52 1.52 0 01-1.39.89l-.03-.06zM17.26 46.5a9.28 9.28 0 01-3.5-.69 9.11 9.11 0 01-3.16-2.07 1.53 1.53 0 01.09-2.16 1.52 1.52 0 012.14.08 6.57 6.57 0 007.74 1 1.53 1.53 0 111.34 2.74 10.498 10.498 0 01-4.65 1.14v-.04z"
                ></path>
                <path
                    fill="#F38620"
                    d="M43.26 53.8a1.51 1.51 0 01-1.49-1.22l-4.56-22.72-1.81 5.79a1.52 1.52 0 01-1.46 1.07h-12a1.53 1.53 0 010-3.05H32.8l3.26-10.4a1.52 1.52 0 013 .16l4.26 21.23 6.18-29.71a1.52 1.52 0 012.455-.875c.273.222.462.53.535.875l5 24 1.58-4.25a1.51 1.51 0 011.43-1h13.77a1.53 1.53 0 010 3.05H61.56l-3.07 8.2a1.52 1.52 0 01-2.92-.22l-4.59-21.97-6.21 29.83a1.51 1.51 0 01-1.49 1.21h-.02z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M24.98 53.8a8.47 8.47 0 01-6.5-2.69 9 9 0 01-1.88-6.37 1.533 1.533 0 011.706-1.589A1.53 1.53 0 0119.6 45.09a5.92 5.92 0 001.18 4 5.6 5.6 0 004.26 1.62 1.53 1.53 0 010 3l-.06.09zM67.08 56.85a7.53 7.53 0 01-4.71-1.63 1.53 1.53 0 011.89-2.4 4.55 4.55 0 006.4-6.39 1.524 1.524 0 112.4-1.88 7.53 7.53 0 011.62 4.7 7.61 7.61 0 01-7.6 7.6z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_33_656">
                    <path fill="#fff" d="M0 0H96.53V86.75H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Nerve;
