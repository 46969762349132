import React from "react";

function Skin() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="84"
            height="87"
            fill="none"
            viewBox="0 0 84 87"
        >
            <g clipPath="url(#clip0_33_695)">
                <path
                    fill="#F38620"
                    d="M40.97 52.49a2.565 2.565 0 10.004-5.128 2.565 2.565 0 00-.004 5.129zM33.423 64.572a2.564 2.564 0 10.004-5.128 2.564 2.564 0 00-.004 5.128zM49.042 61.58a2.565 2.565 0 10-2.564 2.563 2.541 2.541 0 002.564-2.564zM20.05 70.474a2.55 2.55 0 10.03 5.1 2.55 2.55 0 00-.03-5.1zM19.78 57.425a2.542 2.542 0 00-.746-1.81 2.566 2.566 0 00-3.629 3.628 2.564 2.564 0 004.375-1.818zM28.357 43.126a2.57 2.57 0 10-.015 5.142 2.57 2.57 0 00.015-5.142z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M81.518 33.043a7.672 7.672 0 00-3.96-2.074l.767-.774a7.72 7.72 0 000-10.913 7.602 7.602 0 00-3.96-2.114l.09-.09A7.72 7.72 0 1063.542 6.165l-.09.09a7.713 7.713 0 00-13.028-3.967l-17.72 17.72a13.188 13.188 0 00-3.85-10.007l-.96-.968a1.707 1.707 0 00-2.412 0c-.125.124-12.199 12.143-19.704 19.697-3.822 3.85-2.468 9.49-1.272 14.465 1.078 4.506 2.073 8.764-.38 11.252L.498 58.068a1.71 1.71 0 002.418 2.42l3.629-3.63c3.836-3.835 2.48-9.481 1.285-14.457-1.085-4.514-2.073-8.77.374-11.259 6.095-6.137 15.204-15.205 18.473-18.48a9.815 9.815 0 01-.241 13.614l-8.902 8.902a1.71 1.71 0 002.419 2.42l32.89-32.892a4.299 4.299 0 016.082 6.069L39.657 30.05a1.708 1.708 0 002.412 2.419L65.96 8.577a4.299 4.299 0 016.075 6.082L48.15 38.544a1.71 1.71 0 102.418 2.419l19.262-19.269a4.3 4.3 0 016.082 6.082L56.645 47.038a1.71 1.71 0 002.418 2.419l13.961-13.96a4.299 4.299 0 016.082 6.074c-.152.152-26.07 25.98-31.474 31.474-3.006 3.055-5.764 3.2-8.68 3.345-2.633.138-5.363.276-7.851 2.66l-4.838 4.839a1.712 1.712 0 001.21 2.92 1.71 1.71 0 001.209-.501l4.782-4.79c1.541-1.472 3.352-1.569 5.633-1.686 3.124-.16 7-.353 10.947-4.361 5.391-5.48 31.301-31.301 31.446-31.453a7.72 7.72 0 000-10.913l.028-.062z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_33_695">
                    <path fill="#fff" d="M0 0H83.778V86.75H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Skin;
