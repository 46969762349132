import React, { ReactElement } from "react";

import colors from "@styles/variables/bestzantaclawyersVariables.module.scss";
import Nerve from "./icons/Nerve";
import VitaminB from "./icons/VitaminB";
import Heart from "./icons/Heart";
import Skin from "./icons/Skin";
import Lung from "./icons/Lung";
import Blood from "./icons/Blood";

import FeatureList from "@components/shared/featureList";

const sideEffectList = [
    {
        title: "Nervous System Disorders",
        icon: <Nerve />,
    },
    {
        title: "Arrhythmia or Tachycardia (Irregular or Rapid Heartbeat)",
        icon: <Heart />,
    },
    {
        title: "Vitamin B-12 Deficiency",
        icon: <VitaminB />,
    },
    {
        title: "Increased Risk of Pneumonia",
        icon: <Lung />,
    },
    {
        title: "Skin Rashes and Hair Loss",
        icon: <Skin />,
    },
    {
        title: "Low Platelet Levels in the Blood",
        icon: <Blood />,
    },
];

export default function SideEffects(): ReactElement {
    return (
        <FeatureList
            featureList={sideEffectList}
            mainTitle="Zantac Injuries & Side Effects"
            mainDescription={
                <>
                    <span className="block">
                        NDMA, recognized for its cancer-causing potential, also
                        acts as a potent hepatotoxin. Exposure to NDMA in the
                        short term can lead to liver damage, evidenced by
                        conditions such as liver fibrosis and scarring.
                        Long-term exposure is linked with an increased risk of
                        developing tumors in organs, including the liver,
                        kidneys, and lungs. The range of symptoms attributed to
                        excessive NDMA exposure is extensive. It includes
                        headaches, fever, and nausea, progressing to more
                        serious conditions such as jaundice, vomiting, abdominal
                        cramps, liver enlargement, and a decline in liver,
                        kidney, and lung function. Dizziness is also a noted
                        symptom.
                    </span>
                    <span className="block mt-4 color-[#7d7d7d] font-bold text-lg">
                        Other adverse effects that have been associated with
                        Zantac include:
                    </span>
                </>
            }
            classes={{
                mainSectionClasses:
                    "mx-auto lg:max-w-7xl flex justify-center items-start flex-col px-8 mb-10",
                mainTitleClasses:
                    "text-left font-bold leading-10 text-2xl mb-8",
                innerSectionClasses:
                    "flex flex-wrap justify-center mt-12 gap-16",
                oneBoxClasses:
                    "flex flex-col items-center justify-center w-52 md:w-64 mx-10 text-center",
                titleClasses: "mt-2 text-sm md:text-lg",
                mainDescriptionClasses:
                    "text-left text-sm md:text-lg leading-8",
                iconClasses: " ",
            }}
            colors={{
                mainDescriptionColor: colors.grey,
                mainTitleColor: colors.blue,
                titleColor: colors.grey,
            }}
        />
    );
}
