import React from "react";

function Lung() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="99"
            height="87"
            fill="none"
            viewBox="0 0 99 87"
        >
            <g clipPath="url(#clip0_33_685)">
                <path
                    fill="#F38620"
                    d="M88.626 17.538a21.333 21.333 0 00-30.753-9.903 1.435 1.435 0 001.497 2.448 18.45 18.45 0 11-5.209 26.74 1.434 1.434 0 00-2.3 1.712 21.286 21.286 0 0017.123 8.617A21.327 21.327 0 0088.64 17.538h-.014z"
                ></path>
                <path
                    fill="#F38620"
                    d="M68.984 15.126a10.721 10.721 0 110 21.443 10.721 10.721 0 010-21.443zm0 24.284a13.577 13.577 0 10-9.6-3.976 13.487 13.487 0 009.6 3.976z"
                ></path>
                <path
                    fill="#F38620"
                    d="M68.984 30.726a4.891 4.891 0 10-4.887-4.878 4.886 4.886 0 004.887 4.891v-.013z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M36.35 50.57c-2.993.817-5.432.67-7.045-.447a4.101 4.101 0 01-1.559-1.877l-.111.036c.406-1.581.808-3.127 1.241-4.583l.027-.094a33.948 33.948 0 011.676-4.574 13.947 13.947 0 011.786-2.949c.08-.09.157-.183.233-.277v-.022a6.84 6.84 0 012.84-1.984 1.436 1.436 0 00.707-2.391 1.433 1.433 0 00-1.67-.289 9.614 9.614 0 00-4.022 2.783l-.107.116c-1.992 2.462-5.262 1.34-7.036.487a1.434 1.434 0 10-1.268 2.569c1.566.817 3.299 1.267 5.066 1.313h.616a39.692 39.692 0 00-1.582 4.436c-.745 2.05-1.786 2.234-3.73 2.52-1.943.286-4.252.67-6.214 3.26a1.434 1.434 0 102.283 1.734c1.264-1.67 2.707-1.898 4.378-2.158a15.729 15.729 0 001.939-.397c0 .058-.031.12-.05.183-1.634 6.388-3.332 12.991-7.638 15.31a1.434 1.434 0 001.34 2.524c4.62-2.484 6.647-8.104 8.255-14.041.237.223.486.431.747.625.935.67 2 1.136 3.127 1.367.269.378.481.794.63 1.233a3.03 3.03 0 01-.684 3.105 1.436 1.436 0 00.61 2.342 1.435 1.435 0 001.557-.466 6.16 6.16 0 001.496-3.127 5.923 5.923 0 00-.255-2.747c0-.058-.035-.112-.058-.166a17.111 17.111 0 003.19-.58 1.433 1.433 0 00.358-2.652 1.435 1.435 0 00-1.113-.114l.04-.009zM79.008 49.153c.309.269.589.568.836.894a1.434 1.434 0 002.282-1.729c-.368-.482-.78-.93-1.228-1.34a1.435 1.435 0 10-1.89 2.153v.022zM61.246 53.098a17.11 17.11 0 003.19.58 5.945 5.945 0 00-.313 2.913 6.186 6.186 0 001.492 3.127 1.443 1.443 0 001.09.496 1.434 1.434 0 001.081-2.372 3.016 3.016 0 01-.705-3.029c.147-.469.367-.912.652-1.313a8.144 8.144 0 003.127-1.362c.26-.194.51-.403.746-.626 1.608 5.937 3.636 11.557 8.256 14.04.208.113.442.172.678.17a1.434 1.434 0 00.68-2.68c-3.825-2.055-5.589-7.492-7.085-13.156a1.435 1.435 0 00-1.425-1.296c-.416 0-1.56.215-1.859.215a1.428 1.428 0 00-1.166.599c-.16.162-.334.311-.518.446-1.613 1.18-4.092 1.34-7.148.518a1.434 1.434 0 00-.773 2.73z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M38.557 72.044c-1.21 2.372-3.346 2.707-7.326 3.127-3.762.406-8.439.893-13.156 4.4-3.753 2.77-7.394 4.298-10.275 4.298-1.555 0-2.82-.447-3.654-1.34-.93-.974-1.372-2.48-1.287-4.36.608-13.277 4.7-30.056 10.681-43.78 2.922-6.7 6.134-12.316 9.283-16.198 3.15-3.882 6.254-6.013 8.832-6.013 2.068 0 3.994 1.376 5.727 4.096 2.368 3.713 4.195 14.505 4.552 26.853.38 12.986-.924 24.06-3.395 28.903l.018.014zm15.886-40.242a1.43 1.43 0 00-.858-1.313 9.922 9.922 0 00-4.074-1.09 8.698 8.698 0 00-5.486 2.086c-.17-1.492-.362-2.935-.576-4.31a10.521 10.521 0 002.725-4.405c.508-1.646.77-3.357.777-5.08.067-2.622 0-15.711-.022-16.27a1.434 1.434 0 10-2.868 0c0 .135.089 13.599 0 16.172-.063 2.453-.478 4.32-1.31 5.808-.812-3.95-1.786-6.858-2.957-8.662a13.437 13.437 0 00-3.734-4.021 7.804 7.804 0 00-4.41-1.385c-3.524 0-7.24 2.381-11.056 7.076-3.315 4.079-6.665 9.909-9.69 16.855A141.56 141.56 0 003.52 55.332 119.42 119.42 0 00.013 78.074c-.125 2.68.595 4.915 2.078 6.47C3.48 86 5.459 86.776 7.818 86.776c3.52 0 7.657-1.68 11.958-4.856 4.097-3.025 8.171-3.467 11.763-3.86 3.909-.447 7.594-.826 9.573-4.686 1.434-2.814 2.484-7.17 3.127-12.955.585-5.761.78-11.555.586-17.342-.08-2.779-.237-5.504-.447-8.113.194-.078.37-.196.514-.348 1.465-1.541 3.02-2.328 4.632-2.328a6.076 6.076 0 012.783.778 1.434 1.434 0 002.149-1.242l-.013-.023z"
                ></path>
                <path
                    fill="#4C98B1"
                    d="M52.089 22.497v.067a1.434 1.434 0 002.791-.447c0-.175-.033-.35-.098-.513a12.734 12.734 0 01-.54-3.963c-.067-2.573.022-16.037.022-16.171a1.434 1.434 0 00-2.868 0c0 .558-.09 13.647-.022 16.27-.004 1.627.23 3.246.692 4.806l.023-.049zM98.325 77.797a119.414 119.414 0 00-3.507-22.742 141.838 141.838 0 00-4.601-15.002 1.434 1.434 0 10-2.632 1.117c4.503 12.245 7.367 25.571 7.876 36.757.085 1.88-.357 3.386-1.286 4.36-.836.871-2.1 1.34-3.65 1.34-2.864 0-6.505-1.527-10.275-4.297-4.717-3.485-9.381-3.994-13.156-4.4-3.98-.447-6.116-.769-7.326-3.128-2.176-4.257-3.503-14.16-3.52-25.119v-.045a1.434 1.434 0 00-2.869 0v.032c.008 4.506.245 9.009.71 13.49.64 5.781 1.69 10.137 3.128 12.956 1.974 3.86 5.664 4.262 9.573 4.686 3.574.393 7.666.835 11.762 3.86 4.302 3.176 8.44 4.851 11.96 4.856 2.354 0 4.333-.769 5.722-2.234 1.483-1.55 2.202-3.788 2.077-6.469l.014-.017z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_33_685">
                    <path fill="#fff" d="M0 0H98.347V86.75H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Lung;
