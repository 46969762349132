import React from "react";

function VitaminB() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="87"
            fill="none"
            viewBox="0 0 80 87"
        >
            <path
                fill="#4C98B1"
                d="M35.4 15.951a35.4 35.4 0 1035.399 35.396 35.437 35.437 0 00-35.4-35.396zm0 68.108a32.713 32.713 0 1132.712-32.712A32.75 32.75 0 0135.4 84.059z"
            ></path>
            <path
                fill="#F38620"
                d="M45.619 49.742a10.224 10.224 0 002.71-6.908c0-4.434-3.033-9.512-9.705-10.19H27.738c-.944 0-1.85.374-2.518 1.041a3.554 3.554 0 00-1.044 2.515v30.367a3.545 3.545 0 002.2 3.282c.432.178.895.27 1.362.268h12.87c.094.007.188.007.282 0 2.723-.24 5.256-1.5 7.089-3.525a10.811 10.811 0 002.794-7.399 10.993 10.993 0 00-5.154-9.45zM29.063 37.51h9.184c3.837.439 5.203 3.132 5.203 5.325s-1.373 4.88-5.203 5.318h-9.184V37.51zm11.401 27.722h-11.4v-12.2h11.393a6.18 6.18 0 013.763 2.076 6.163 6.163 0 010 8.049 6.18 6.18 0 01-3.763 2.075h.007z"
            ></path>
            <circle cx="61.051" cy="18.74" r="16.322" fill="#fff"></circle>
            <path
                fill="#4C98B1"
                d="M67.942 19.364h-2.911V9.521c0-.28-.102-.549-.284-.747a.929.929 0 00-.682-.311h-6.029a.928.928 0 00-.683.31 1.11 1.11 0 00-.283.748v9.843h-2.91a.91.91 0 00-.538.179 1.044 1.044 0 00-.356.474 1.15 1.15 0 00-.055.61c.037.206.129.395.264.543l6.892 7.537c.181.198.427.31.684.31a.927.927 0 00.683-.31l6.891-7.538a1.09 1.09 0 00.266-.54 1.15 1.15 0 00-.055-.612 1.043 1.043 0 00-.356-.475.909.909 0 00-.538-.178zm-6.89 8.46l-6.678-7.308H57.6c.14 0 .274-.06.373-.168a.605.605 0 00.154-.408V9.617h5.85v10.321c0 .153.056.3.155.408a.506.506 0 00.373.169h3.227l-6.68 7.308z"
            ></path>
            <path
                fill="#4C98B1"
                d="M61.043 0a18.732 18.732 0 1018.732 18.728A18.75 18.75 0 0061.043 0zm0 34.773a16.045 16.045 0 1116.034-16.045 16.064 16.064 0 01-16.034 16.045z"
            ></path>
        </svg>
    );
}

export default VitaminB;
