import React from "react";

function Blood() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="92"
            height="87"
            fill="none"
            viewBox="0 0 92 87"
        >
            <g clipPath="url(#clip0_33_703)">
                <path
                    fill="#4C98B1"
                    d="M70.703 86.749a1.118 1.118 0 01-.853-.395L50.623 63.68a1.118 1.118 0 01.852-1.843h10.053v-31.28a1.119 1.119 0 011.12-1.119H78.76a1.119 1.119 0 011.12 1.12v31.276H89.93a1.118 1.118 0 01.854 1.843L71.556 86.354a1.118 1.118 0 01-.853.395zm-16.81-22.677l16.81 19.825 16.812-19.825H78.76a1.12 1.12 0 01-1.119-1.12V31.677H63.766v31.277a1.119 1.119 0 01-1.119 1.119h-8.755zm-19.38 22.674A34.522 34.522 0 014.826 34.62c4.812-9.502 12.43-18.337 17.976-24.078C28.807 4.328 33.758.288 33.807.248a1.12 1.12 0 011.412 0C35.353.36 48.788 11.353 58.58 25.35a1.12 1.12 0 01-1.834 1.283C48.415 14.723 37.383 5.015 34.51 2.574c-1.558 1.322-5.523 4.784-10.12 9.546-5.439 5.632-12.904 14.288-17.585 23.544l-.037.068a32.28 32.28 0 0046.857 42.51 1.118 1.118 0 111.326 1.802 34.233 34.233 0 01-20.44 6.705v-.003z"
                ></path>
                <path
                    fill="#F38620"
                    d="M23.515 48.965c0 3.73 2.793 6.765 6.225 6.765 3.431 0 6.224-3.035 6.224-6.765 0-3.73-2.796-6.764-6.224-6.764-3.427 0-6.225 3.035-6.225 6.764zM41.772 55.723h.105a1.118 1.118 0 001.012-.637l3.399-7.2 3.397 7.2a1.119 1.119 0 002.096-.198l2.91-11.284a1.12 1.12 0 10-2.168-.56l-2.137 8.281-2.994-6.348a1.118 1.118 0 00-.613-.76 1.146 1.146 0 00-1.376.31 1.118 1.118 0 00-.22.45l-2.995 6.348-2.136-8.28a1.12 1.12 0 10-2.168.56l2.91 11.283a1.119 1.119 0 00.978.835zM15.417 55.723h5.696a1.12 1.12 0 000-2.238h-4.577V43.32a1.12 1.12 0 00-2.238 0v11.284a1.119 1.119 0 001.12 1.119z"
                ></path>
                <path
                    fill="#fff"
                    d="M25.753 48.965c0-2.495 1.788-4.526 3.987-4.526 2.197 0 3.986 2.031 3.986 4.526 0 2.496-1.79 4.527-3.986 4.527s-3.987-2.031-3.987-4.527z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_33_703">
                    <path fill="#fff" d="M0 0H91.051V86.75H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Blood;
